<!--<div *ngIf="environmentType !== 'PROD'"class="alert alert-warning sticky info" role="alert" style="margin: 0px;">
    <span>
        Currently you are working on {{environmentType}} environment
    </span>
</div>-->
<bis-scrolling-text></bis-scrolling-text>
<!--<div class="alert alert-warning sticky info" role="alert" style="margin: 0px;" *ngIf="alertMsg">{{ alertMsg }}</div>-->
<nav class="navbar navbar-expand-lg navbar-custom">
  <a class="navbar-brand navbar-custom-logo" routerLink='/'>
    <img src="../../../assets/images/new-logo.svg" alt="logo" style="width:100%">
    <!-- <span>Insurance Brokers & Consultant</span> -->
  </a>

  <button class="navbar-toggler mobile-nav-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="fa fa-fw"></span>
  </button>

  <div class="collapse navbar-collapse mr-auto" id="navbarSupportedContent">
    <ul class="navbar-nav">
      <li class="nav-item active">
      <!-- <a class="nav-link" routerLink='/bondmanagement/v2' routerLinkActive="active" *ngIf="validateUser([availableUserRoles.EPIC_ADMIN, availableUserRoles.EPIC_SUPER_ADMIN, availableUserRoles.MASTER_BROKERS_HQ, availableUserRoles.MASTER_BROKER, availableUserRoles.VIEW_ONLY])">iBond</a> -->
      <a class="nav-link" routerLink='/bondmanagement/v2' routerLinkActive="active" (click)="setReduxData()" *ngIf="isMenuPrefrence(menuPreferencesEnum.IBOND)">iBond</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"  routerLink='/invoice' routerLinkActive="active" (click)="setReduxData()"  *ngIf="isMenuPrefrence(menuPreferencesEnum.INVOICE)">Invoice</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="setReduxData()"  routerLink='/reports' routerLinkActive="active"
        *ngIf="isMenuPrefrence(menuPreferencesEnum.REPORTS)"
        >{{ 'Reports.lblReports' | l10n }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="setReduxData()" routerLink='/administration' routerLinkActive="active" *ngIf="isMenuPrefrence(menuPreferencesEnum.ADMINISTRATION)">Administration</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="setReduxData()" routerLink='/accounting' routerLinkActive="active" *ngIf="isMenuPrefrence(menuPreferencesEnum.ACCOUNTING)">
          {{ 'Accounting.lblAccounting' | l10n }}
        </a>
      </li>
      <li class="nav-item" *ngIf="isMenuPrefrence(menuPreferencesEnum.ABI)">
        <a class="nav-link" (click)="setReduxData()" routerLink='/abi' routerLinkActive="active">
          ABI
        </a>
      </li>
      <li class="nav-item" *ngIf="isMenuPrefrence(menuPreferencesEnum.ASI)">
        <a class="nav-link" (click)="setReduxData()" routerLink='/asi' routerLinkActive="active">
          ASI
        </a>
      </li>
      <li class="nav-item" *ngIf="isMenuPrefrence(menuPreferencesEnum.USERS) && isActionAvailable(operationPreferencesEnum.ADD_EDIT_USER_REQUEST)">
        <a class="nav-link" routerLink='/chbusers' routerLinkActive="active">
            {{ 'Users.lblUsers' | l10n }}
        </a>
      </li>
    </ul>

  </div>

  <div class="">
    <ul class="profile-block">
      <li class="notification-block" >
          <ng-container  *ngIf="isEPICUser()">
        <a class="nav-link" (click)="openNotificationPanel($event, op1)" id="navbarDropdown">
          <img src="../../../assets/images/bell.svg" alt="bell" />
          <span class="notificationCount" *ngIf="notificationCount">{{notificationCount}}</span>
        </a> ​
        <p-overlayPanel #op1 >​
          <div *ngIf="notificationDetails">
            <div *ngFor="let notification of notificationDetails" class="cursor-pointer">
              <a (click)="redirectToApprovUserPage($event, notification, op1)">
                {{notification.clientName}} requested approval for {{notification.count}} users...
              </a>
            </div>
          </div>
        </p-overlayPanel>
    </ng-container >
      </li>
      <li class="dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <span class="username">{{loggedInUserName}} &nbsp;</span>
          <img src="../../../assets/images/nav-user.svg" width="28" class="" alt="user" />
        </a>
        <div class="dropdown-menu more-action" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="changePassword()">Change Password</a>
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </div>
      </li>
    </ul>
  </div>

</nav>



