import { NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel/public_api';
import { setPaymentDetails, setPaymentSearchDetails } from 'src/bis/redux/actions/bond-actions';
import { IBISState } from 'src/bis/redux/store';
import { OperationPreferencesEnum } from 'src/bis/shared/enum/preferences-action.enum';
import { MenuPreferencesEnum, OperationCategoryPreferenceEnum } from 'src/bis/shared/enum/preferences.enum';
import { RoleEnum, RoleIdEnum } from 'src/bis/shared/enum/role.enum';
import { NotificationService } from 'src/bis/shared/services/notification/notification.service';
import { OidcAuthService } from 'src/bis/shared/services/oidc-auth/oidc-auth.service';
import { AuthConfigService } from 'src/bis/shared/services/preferences-config/auth-config.service';
import { LoginConfigurationService } from 'src/bis/shared/services/user-config/login-configuration.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'bis-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  notificationDetails: Array<{ clientId: number, clientName: string, count: number }> =[];
  notificationCount: any;
  /**
   * used store userdetails
   */
  loggedInUserName: string;

  availableUserRoles = RoleIdEnum;
  operationPreferencesEnum =OperationPreferencesEnum;

  /**
   * Used to store alert msg
   */
    alertMsg: string;
    isShow: boolean = true;
    state = 0;

  environmentType= environment.environmentType;
  menuPreferencesEnum = MenuPreferencesEnum;
  constructor(
    private helperService: NotificationService,
    private router: Router,
    private oidcAuthService: OidcAuthService,
    private authConfigService: AuthConfigService,
    protected route: ActivatedRoute,
    protected loginConfigSer: LoginConfigurationService,
    private ngRedux: NgRedux<IBISState>,) {
    }

    validateUser (userList) {
        return this.authConfigService.validateUser(userList)
    }

  ngOnInit() {
      this.getNotification();
      this.getUserDetails();
      this.helperService.subscribeNotification.subscribe(res => {
        this.getNotification();
      });
      this.getAlert();
      this.router.events.forEach(event => {
          if (event instanceof NavigationStart) {
            this.getAlert();
          }

      });
  }

  setReduxData(){
    this.ngRedux.dispatch(setPaymentSearchDetails([]));
    this.ngRedux.dispatch(setPaymentDetails([]));
  }
  /**
   * used to get pending notificaiton
   */
  getNotification() {
    if (
        this.authConfigService.loggedInUserDetails.roleId !== RoleIdEnum.ACCOUNTING_EPIC &&
        this.authConfigService.loggedInUserDetails.roleId !== RoleIdEnum.AGENCY &&
        this.authConfigService.loggedInUserDetails.roleId !== RoleIdEnum.VIEW_ONLY &&
        this.authConfigService.loggedInUserDetails.roleId !== RoleIdEnum.ABI && 
        this.authConfigService.loggedInUserDetails.roleId !== RoleIdEnum.SURETY_SUPER_ADMIN
    ) {
      this.helperService.getNotification().subscribe(
          (result: any) => {
              if (result && result.data) {
                  this.notificationDetails = result.data;
                  this.notificationCount = result.data.length;
              }else{
                this.notificationDetails =[];
              }
          }
      );
        }
  }

  /**
   * used to close notificaiton panel and redirect to approve user page
   */
  redirectToApprovUserPage(event, data, op1: OverlayPanel) {
      this.helperService.clientId = data.clientId;
      op1.hide()
    //   this.router.navigate(['/administration/approveuser']);
      this.router.navigateByUrl('/', {​​​​​​​​skipLocationChange: true}​​​​​​​​).then(()=>
       this.router.navigate(['/administration/approveuser']));
  }

  /**
   * used to open notificaiton panel
   */
  openNotificationPanel(event, overlayPanel: OverlayPanel) {
    if(this.notificationDetails.length){
      overlayPanel.toggle(event);
    }
  }
  /**
   * used to logout
   */
  logout() {
    this.clearData();
      this.oidcAuthService.logout();
      sessionStorage.removeItem('isQuestawebRunning');
  }

  clearData(){
    this.loginConfigSer.removeCache().subscribe(
      {
          next: (res) => {
             
            
              
          },
          error: error => {
          }
      });
  }
  /**
   * used to get logged in user details
   */
  getUserDetails() {
      this.loggedInUserName = this.authConfigService.loggedInUserDetails.firstName + ' ' +
      this.authConfigService.loggedInUserDetails.lastName;
      return this.loggedInUserName;
  }

  /**
   * Used ti navigate to application homepage.
   */
  navigateToHomepage() {
      this.router.navigateByUrl(environment.homepage);
  }

  /**
   * Used to check logged in user type
   */
  isCHBUser() {
      return this.authConfigService.loggedInUserDetails.userCategoryId === RoleEnum.chb;
  }

    /**
   * Used to check logged in user type
   */
     isEPICUser() {
        return this.authConfigService.loggedInUserDetails.userCategoryId === RoleEnum.epic && this.authConfigService.loggedInUserDetails.roleId !== RoleIdEnum.ACCOUNTING_EPIC && this.authConfigService.loggedInUserDetails.roleId !== RoleIdEnum.VIEW_ONLY && this.authConfigService.loggedInUserDetails.roleId !== RoleIdEnum.SURETY_SUPER_ADMIN;
    }
    /**
   * Used to check if action available
   * @param preferenceId Contain Operation Preferences Enum
   */
  isActionAvailable(preferenceId: OperationPreferencesEnum, checkPreference: boolean = true) {
    if (checkPreference) {
      return this.authConfigService.checkActionForOperational(OperationCategoryPreferenceEnum.USER_MANAGEMENT, preferenceId);
    }
    return true;
  }

  /**
   * Used to get alert msg
   */
  getAlert() {
    this.helperService.getAlert().subscribe(
      {
        next: res => {
          if (res) {
            this.alertMsg = res;
          } else {
            this.alertMsg = '';
          }
        }, error: err => {
          this.alertMsg = '';
        }
      });
  }

  changePassword(){
    this.oidcAuthService.changePassword();
  }

  isMenuPrefrence(preferenceId: MenuPreferencesEnum, checkPreference: boolean = true){
    if (checkPreference) {
        return this.authConfigService.checkActionForMenu(preferenceId);
      }
      return true;
    }
    scrollDone() {
        this.state++;
    }
}
